import Swiper from 'swiper';
import { remToPx } from '../utility/utils';

const Home = () => {
	if (!document.querySelector('.main').classList.contains('home')) {
		return;
	}

	console.log('IS HOME SITE');

	/**
	 * Opening Swiper
	 */
	const openingSwiper = new Swiper('.opening-swiper', {
		direction: 'horizontal',
		loop: true,
		slidesPerView: 1,
		spaceBetween: 0,

		pagination: {
			el: '.opening-swiper-pagination',
			type: 'bullets'
		},
		navigation: {
			nextEl: '.opening-swiper-button-next',
			prevEl: '.opening-swiper-button-prev'
		},
		autoplay: {
			delay: 2000
		}
	});

	/**
	 * Unsere Weine Swiper
	 */
	const wineSwiper = new Swiper('.wine-swiper', {
		direction: 'horizontal',
		loop: true,
		spaceBetween: remToPx(1),
		slidesPerView: 1.25,
		centeredSlides: true,

		pagination: {
			el: '.swiper-pagination'
		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev'
		},

		breakpoints: {
			640: {
				slidesPerView: 1.5,
				spaceBetween: remToPx(2)
			},
			960: {
				slidesPerView: 2,
				spaceBetween: remToPx(3)
			},
			1280: {
				slidesPerView: 2,
				spaceBetween: remToPx(4.5)
			}
		}
	});

	/**
	 * Weinstube Swiper
	 */
	const weinstubeSwiper = new Swiper('.weinstube-swiper', {
		direction: 'horizontal',
		loop: true,
		slidesPerView: 1.5,
		centeredSlides: true,
		loopAdditionalSlides: 2,
		loopedSlides: 2,
		spaceBetween: remToPx(1),

		pagination: {
			el: '.swiper-pagination'
		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev'
		},

		breakpoints: {
			640: {
				slidesPerView: 1.5,
				spaceBetween: remToPx(1)
			},
			960: {
				slidesPerView: 2,
				spaceBetween: remToPx(2)
			},
			1280: {
				slidesPerView: 2,
				spaceBetween: remToPx(4)
			}
		}
	});

	/**
	 * Wein erleben Swiper
	 */
	const weinerlebenSwiper = new Swiper('.erleben-swiper', {
		direction: 'horizontal',
		loop: true,
		slidesPerView: 1.125,
		centeredSlides: true,
		loopAdditionalSlides: 2,
		loopedSlides: 2,
		spaceBetween: remToPx(1),

		pagination: {
			el: '.swiper-pagination'
		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev'
		},

		breakpoints: {
			640: {
				slidesPerView: 1.25,
				spaceBetween: remToPx(1)
			},
			960: {
				slidesPerView: 1.75,
				spaceBetween: remToPx(2)
			},
			1280: {
				slidesPerView: 2.5,
				spaceBetween: remToPx(4)
			}
		}
	});
};

export { Home };
