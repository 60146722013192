const getRootElementFontSize = () => parseFloat(getComputedStyle(document.documentElement).fontSize);

module.exports = {
	select: (e) => document.querySelector(e),
	selectAll: (e) => document.querySelectorAll(e),
	selectId: (e) => document.getElementById(e),

	remToPx: (val) => val * getRootElementFontSize(),

	map: (x, a, b, c, d) => ((x - a) * (d - c)) / (b - a) + c,
	lerp: (a, b, n) => (1 - n) * a + n * b,

	randomInRange: (min, max) => Math.floor(Math.random() * (max - min + 1) + min),

	ready: (callback) => {
		if (document.readyState != 'loading') callback();
		else document.addEventListener('DOMContentLoaded', callback);
	}
};
