const { select } = require('../utility/utils');
import { gsap } from 'gsap';

export default class Toggle {
	constructor(
		el,
		{
			trigger = '.trigger',
			container = '.container',
			mainNav = false,
			gsapToggle = false,
			duration = 0.25,
			ease = 'power2.inOut'
		} = {
			trigger: '.trigger',
			container: '.container',
			mainNav: false,
			gsapToggle: false,
			duration: 0.25,
			ease: 'power2.inOut'
		}
	) {
		this.el = select(el) || null;
		if (this.el == null) return console.error(`couldn\'t find element ${el}`);
		this.triggers = this.el.querySelectorAll(trigger) || null;
		this.container = this.el.querySelector(container) || null;
		this.isMainNav = mainNav;
		this.gsapToggle = gsapToggle;
		this.duration = duration;
		this.ease = ease;
		this.isOpen = false;
		this.init();
	}

	init() {
		console.log('INIT TOGGLE');
		if (this.triggers == null) return console.error(`no trigger found`);
		if (this.container == null) return console.error(`no container found`);

		this.triggers.forEach((trigger) => {
			trigger.addEventListener('click', (e) => this.toggle(e));
		});
	}

	open() {
		console.log('OPEN MENU');
		this.el.classList.add('open');
		this.triggers.forEach((trigger) => trigger.classList.add('open'));
		this.container.classList.add('open');

		if (this.isMainNav) document.documentElement.classList.add('overflow');

		if (this.gsapToggle) {
			gsap.set(this.container, {
				height: 'auto'
			});
			gsap.from(this.container, {
				height: 0,
				duration: this.duration,
				ease: this.ease
			});
		}

		this.isOpen = true;
	}

	close() {
		this.el.classList.remove('open');
		this.triggers.forEach((trigger) => trigger.classList.remove('open'));
		this.container.classList.remove('open');

		if (this.isMainNav) document.documentElement.classList.remove('overflow');

		if (this.gsapToggle) {
			gsap.to(this.container, {
				height: 0,
				duration: this.duration,
				ease: this.ease
			});
		}

		this.isOpen = false;
	}

	toggle(e) {
		this.isOpen ? this.close() : this.open();
	}
}
