import 'lazysizes';
import 'swiper/scss';
import 'swiper/scss/pagination';
import 'swiper/scss/navigation';

import { ready } from './utility/utils.js';
import Toggle from './components/toggle.js';

// Pages
import { Home } from './pages/home.js';

ready(() => {
	console.log('DOC READY');
	document.documentElement.classList.add('js');

	const mainNav = new Toggle('.navbar', {
		trigger: '.nav-trigger',
		container: '.main-menu',
		mainNav: true
	});

	Home();
});
